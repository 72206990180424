

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
  }
  
  #card-caixa {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  h2 {
    font-size: 1.5rem;
    color: #333;
  }
  
  
  
  /* Responsividade */
  @media (max-width: 768px) {
    .card-container {
      justify-content: center;
    }
  
    .card {
      width: 100%;
      max-width: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .card-container {
      justify-content: center;
    }
  
    .card {
      width: 100%;
      max-width: 200px;
    }
  }