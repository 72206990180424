.navbar{
    background-color: #333333;
    ;
}

#nav-font{
  font-size: 17px;
}


.bi-person-circle{
  color: white;
  width: 300px;
  text-align: end;
  padding-right: 20px;
   ;
}