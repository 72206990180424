.product-list {
  display: flex;
  justify-content: space-around;
}

.posicao{
  border: 5px solid #ccc;
  border-radius: 60px;
  text-align: center;
  height: 50px;  

}

.product-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}