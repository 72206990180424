.icone-acao{
    margin-left: 7px;
    margin-right: 7px;
}

.red{
    color: rgb(194, 74, 74);
}

.col-acao{
    width: 80px;
}

.butao{
    margin: 20px 20px 0px 20px;
    min-width: 100px;
}

.input{
    text-transform: uppercase;
}

.btn-cli{
    margin-right: 15px;
}

.espaco{
    margin-top: 50px;
}
 
#table-banco{
    font-size: 12px;
 }

 .custom-btn {
    padding-bottom: 1px;
    height: 29px;
    align-items: center;
 }