.icone-acao{
    margin-left: 7px;
    margin-right: 7px;
}

.red{
    color: rgb(194, 74, 74);
}

.col-acao{
    width: 80px;
}

.butao{
    margin: 20px 20px 0px 20px;
    min-width: 100px;
}

.letra-maiuscula{
    text-transform: uppercase;
}

.btn-cli{
    margin-right: 15px;
}

body input{
text-transform: uppercase;

}

.itens-venda{
    width: 500px;
    height: 100vh;
    overflow: auto;
    background-color: transparent;
    margin: 10px;
    border-radius: 10px;
}

.produtos-venda{
    width: 100vw;
    background-color: white;
    margin: 10px;
    border-radius: 10px;
}

.controle{
    background-color: rgb(174, 174, 174);

}

.dados-mesa{
    display: flex;
    justify-content: space-between;
}

.dado-pedido{
    margin-bottom:10px ;
    border-radius: 10px;
    background-color: white;
    

}

.itens-vendido{
    background-color: white;
    border-radius:10px;
    height: 100vh;

}

.container{
    width: 100vw;
    height: 100vh;
    
}