.react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.react-modal-content {
  width: 100%;
  height: 650px;
  max-width: 600px;
  background: #fff;
  padding: 1rem;
  position: relative;
  border-radius: 0.24rem;
  margin-left: 15px;
  margin-right: 15px;
}

.grid-pesquisa {
  height: 400px; /* Mantém a altura fixa */
  overflow-y: auto; /* Permite rolagem vertical */
}

/* Se necessário, ajuste a tabela para ter uma largura máxima */
.scrollable-table {
  max-height: 600px; /* Altura ajustável conforme necessário */
}

  