*{
margin: 0;
padding: 0;

}


.parent{
    display: flex;
    align-items: center;
    
    justify-content: center;
    height: 50vh;
    color: gray;
    border-style: dashed;
    border-color: gray;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, .5);
}

