.listaPedidos{
    margin-top: -100px;
}

select{
    border: 0;
    background-color: #fff;

    cursor: pointer;
    
}

.dados-venda{
    border: solid;
    border-width: 0.5px;
    border-radius:10px;
    padding: 25px;
    
}



#tabela-venda{
    margin-top: 1%;
    font-size: 12px;
}


@media screen and (max-width: 780px) {
    #bloco-widget{
        overflow: auto;
    }

    #tabela-venda{
        overflow-x: auto;
        border-collapse: collapse;
        width: 100%;
    }
    
}

#campoOculto {
    display: none;
}

#campoOculto2 {
    display: none;
}  

.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0px;
    padding: 10px;
}
.item {
    text-align: center;
    flex: 1; /* Permite que os itens se expandam igualmente */
}
.item h3 {
    margin: 0;
}
.item h1 {
    margin: 0;
    font-size: 1.5em; /* Ajuste o tamanho conforme necessário */
}
#botao-pagar{
    margin-bottom: -23px;
}


.card {
    border: 2px solid;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(103, 65, 226, 0.1);
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card h3 {
    margin: 0;
    color: #01010f;
  }
  
  .card h1 {
    margin: 0;
    color: #000;
  }
  #card-subtotal {
    border-left: 5px solid #007bff; /* Exemplo de cor azul */
  }
  
  #card-discount {
    border-left: 5px solid #28a745; /* Exemplo de cor verde */
  }
  
  .card-total {
    border-left: 5px solid #dc3545; /* Exemplo de cor vermelha */
  }