.importacao{
    justify-content: space-between;
}

.react-modal-overlay{
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
  }
  
  
  .react-modal-content{
    width: 100%;
    height: 650px;
    width: 750px;
    background: #fff;
    padding: 1rem;
    position: relative;
    border-radius: 0.24rem;
    margin-left:15px ;
    margin-right:15px ;
  }

.footer{
  height: 25px;
}

.produtos{
  height:450px ;
}

.lista-produtos{
  height: calc(100% - 500px);
  margin-right: 20px;
  margin-left: 20px;
  overflow: auto;
}

.footer-cart{
margin-top: 20px;  
border-top: 2px solid #e7e7e7 ;   
}


  #inputValorTotal{
    font-size: 12px;
  }

  #data-label{
    font-size: 12px;
  }
  
  #inputCodigo{
    font-size: 12px;
  }

  #form-label{
    font-size: 12px;
  }

  #botao-opcoes{
    font-size: 10px;
  }
