.icone-acao{
    margin-left: 7px;
    margin-right: 7px;
}

.red{
    color: rgb(194, 74, 74);
}

.col-acao{
    width: 80px;
}

.butao{
    margin: 20px 20px 0px 20px;
    min-width: 100px;
}

.input{
    text-transform: uppercase;
}

.btn-cli{
    margin-right: 15px;
}

.espaco{
    margin-top: 30px;
}


.btn-cli{
    margin-right: 15px;
}

#table-contas{
    font-size: 12px;
}





#custom-btn-3 i{
    margin-right: 6px;          /* Espaço entre o ícone e o texto */
    display: inline-flex;       /* Usa flexbox para o ícone */
    align-items: center; 
    font-size: 15px;
}
.custom-select-container {
    display: flex;
    justify-content: flex-end; /* Alinha à direita */
    margin-bottom: 10px; /* Espaçamento inferior, ajuste conforme necessário */
    margin-top: 0; /* Remove o deslocamento negativo */
    align-items: center; /* Alinha os itens verticalmente ao centro */
}


  .custom-select {
    max-width: 150px; /* Define a largura máxima do select */
    font-size: 0.875rem; /* Reduz o tamanho da fonte */
  }


 #custom-btn-3 {
    align-items: center;
    align-self: center;
    text-align: center;
    margin-bottom: 5px;
    height: 29px;
}

 
  
  
  