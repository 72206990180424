.content{
    width: 120px;
    height: 120px;
    border-radius: 10px;
    color: white;
    align-items: center;
    background-color: #f58220;
    border: 5px;
    border-color: black;
      
    
}

span{
    font-size: x-large;
    align-items: center;
    text-align: center;

}