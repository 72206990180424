body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imgVazia {
  align-items: center;
  text-align: center;
}

#banner{
  background-color: #333333;
  color: #ffffff;
  padding: 20px 0 20px 0;
  height: 600px;

}

#banner img{
width:100%;  
}



.featuretes-box{
  text-align: center;
  padding: 5% ;
 

}

.icon{
  margin-bottom: 15px;
  color: #f58220;
}




