.icone-acao{
    margin-left: 7px;
    margin-right: 7px;
}

.red{
    color: rgb(194, 74, 74);
}

.col-acao{
    width: 80px;
}

.butao{
    margin: 20px 20px 0px 20px;
    min-width: 100px;
}

.letra-maiuscula{
    text-transform: uppercase;
}

.btn-cli{
    margin-right: 15px;
}

body input{
text-transform: uppercase;

}


#table-prod{
    font-size: 12px;
    width: 100%;
    height: 30px;
}



.table-fixed tbody {
    height: 300px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
    float: left;
    position: relative;

    &::after {
        content: '';
        clear: both;
        display: block;
    }
}

.etiquetaIcon {
    min-width: 33px;
    text-align: center;
}

#table-servico{
    font-size: 12px;
}

#inputCodigo{
    font-size: 12px;
}
#inputDescricao{
    font-size: 12px;
}
#inputGrupo{
    font-size: 12px;
}
#inputUnidade{
    font-size: 12px;
}
#inputNcm{
    font-size: 12px;
}
#inputMarkup{
    font-size: 12px;
}
#inputCompra{
    font-size: 12px;
}
#inputsugestao{
    font-size: 12px;
}
#inputVenda{
    font-size: 12px;
}
#botao-produto{
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
}
*{
    font-size: 12px;
}
#button-addon2{
    font-size: 12px;
    height: 29px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;    
}

.dropdown-menu.show {
    display: block;
  }


  /* Css imagens */

  #associar-imagem-produto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
  }
  
  
  #codigo-produto {
    margin: 10px 0;
    padding: 8px;
    font-size: 16px;
    width: 300px;
    max-width: 80%;
  }
  
  #upload-imagem {
    margin: 10px 0;
    padding: 8px;
    font-size: 16px;
    width: 300px;
    max-width: 80%;
  }
  
  
  #associar-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  
  #associar-btn:hover {
    background-color: #45a049;
  }
  
  
  #imagem-preview {
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }