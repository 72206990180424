.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    color: white; 
    background-color: #333333; 
    text-align: center;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 2px;
    
    
  }
  

  #pFooter{
    margin-top: 6px;
  }
