.icone-acao{
    margin-left: 7px;
    margin-right: 7px;
}

.red{
    color: rgb(194, 74, 74);
}

.col-acao{
    width: 80px;
}

.butao{
    margin: 20px 20px 0px 20px;
    min-width: 100px;
}

.input{
    text-transform: uppercase;
}

.btn-cli{
    margin-right: 15px;
}

#table-grupos{
    font-size: 12px;
}

