.icone-acao{
    margin-left: 7px;
    margin-right: 7px;
}

.red{
    color: rgb(194, 74, 74);
}

.col-acao{
    width: 80px;
}

.butao{
    margin: 20px 20px 0px 20px;
    min-width: 100px;
}

.input{
    text-transform: uppercase;
}

.btn-cli{
    margin-right: 15px;
}

.bi{
    font-size: 20px;
    margin: 7px;
    
 }

 .red{
    color: red;
 }

 .blue{
    color: blue;
 }

 .green{
    color: green;
 }

 .letra-maiuscula {
    text-transform: uppercase;
}

.botao{

    margin-bottom: 50px;
}


.teste{
    color: azure;
}

#table-cliente{
    font-size: 12px;
}
#botao-cadastro{
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
}
 
