.box-emitente{
    background-color: rgb(240, 240, 240);
    padding-bottom: 20px;
    border-radius: 15px;

    
}

.box-destinatario{
    background-color: rgb(240, 240, 240);
    padding-bottom: 20px;
    border-radius: 15px;
       
}

.status-Digitação {
    background-color: gray;
    color: white;
    border-radius: 5px;
    width: 100px;
}

.status-Autorizada {
    background-color: #198754;
    color: white;
    border-radius: 5px;
    width: 100px;
}

.status-Transmitida {
    background-color: #0d6efd;
    color: white;
    border-radius: 5px;
    width: 100px;
}

.status-Cancelada {
    background-color: #dc3545;
    color: white;
    border-radius: 5px;
    width: 100px;
}

.status-Assinada {
    background-color: #0dcaf0;
    color: white;
    border-radius: 5px;
    width: 100px;
}

.footer{
    height: 200px;
}

.produtos{
    height: 200px;
    overflow: auto;
    align-items: flex-start;
}

.conteudo{
    height: 100%;
}

.react-modal-overlay{
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
  }
  
  
  .pagamento-modal-content{
    width: 500px;
    height: 480px;
    max-width: 600px;
    background: #fff;
    padding: 1rem;
    position: relative;
    border-radius: 0.24rem;
    margin-left:15px ;
    margin-right:15px ;
    align-items: center;
    justify-content: center;
  }

.grid-pesquisa{
  height: 480px;
}

  
#table-nfe {
    margin-top: 1%;
    font-size: 12px;
}

@media screen and (max-width: 780px) {
    #widget-nfe{
        overflow-y: auto;
    }
    #table-nfe{
        overflow-x: auto;
    }
}

